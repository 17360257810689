import axios from 'axios';
import { fakeAuth } from './RouteHelper';

import ES6Promise from 'es6-promise';
import {
	API_END_POINT,
	ENDPOINT_BANK,
	OTP_API_END_POINT,
	COMMON_SERVICES_API_END_POINT,
} from '../../../_config/app.config';
ES6Promise.polyfill();

export function getUrl() {
	if (process.env.NODE_ENV === 'localhost') {
		return API_END_POINT;
	} else {
		return API_END_POINT;
	}
}

export function axiosAPIVerification() {
	return axios.create({
		baseURL: ENDPOINT_BANK,
		// withCredentials: true,
		headers: {
			Authorization: `Bearer ${fakeAuth.getToken()}`,
		},
	});
}
export function axiosAPI() {
	return axios.create({
		baseURL: API_END_POINT,
		withCredentials: true,
		headers: {
			Authorization: `Bearer ${fakeAuth.getToken()}`,
		},
	});
}

export function axiosCommonServicesAPI() {
	return axios.create({
		baseURL: COMMON_SERVICES_API_END_POINT,
		withCredentials: true,
		headers: {
			Authorization: `Bearer ${fakeAuth.getToken()}`,
		},
	});
}

export function axiosOriginAPI() {
	return axios.create({
		baseURL: window.location.origin,
		withCredentials: true,
		headers: {
			Authorization: `Bearer ${fakeAuth.getToken()}`,
		},
	});
}
export function getApiServiceUrl() {
	if (process.env.NODE_ENV === 'localhost') {
		return ENDPOINT_BANK;
	} else {
		return ENDPOINT_BANK;
	}
}

export function getOTPServiceUrl() {
	if (process.env.NODE_ENV === 'localhost') {
		return OTP_API_END_POINT;
	} else {
		return OTP_API_END_POINT;
	}
}
